import { Row, Col, Alert, Form, Button, Spinner, Badge } from "react-bootstrap";
import { ResultList } from "../components/search/ResultList";
import React, { useState, useEffect } from "react";
import ResultHeader from "../components/search/ResultHeader";
import ResultActions from "../components/search/ResultActions";
import ResultCategories from "../components/search/ResultCategories";
import Pagination from "../components/search/Pagination";
import EmailExportMdl from "../components/search/EmailExportMdl";
import { updateTokens } from "./../context/checkTokens";
import NHSLibkey from "../components/search/NHSLibkey";
import NHSLibkeyMdl from "../components/search/NHSLibkeyMdl";
// import SurveyMdl from "../components/search/SurveyMdl";
import ConnectedResults from "../components/search/ConnectedResults";

import {
  setQueryStringValue,
  getQueryStringValue,
} from "../components/search/queryString";
import { useAuth } from "./../context/auth";
import _ from "underscore";
import { isMobile } from "react-device-detect";
const axios = require("axios");
const qs = require("query-string");
const store = require("store");
const { sanatizeBooleans } = require("../components/search/sanatizeBooleans");
const Searchresult = (props) => {
  const parsed = qs.parse(props.location.search);
  const { authTokens, setAuthTokens } = useAuth();
  const [hasError, setErrors] = useState(false);
  const [data, setData] = useState({});
  const [userdata, setUserData] = useState({});
  const [userId, setUserId] = useState(null);
  const [catToggle, setCatToggle] = useState(true);
  const [dateRefresh, setDateRefresh] = useState(true);
  const [currentPage, setCurrentPage] = useState(
    !_.isUndefined(parsed.page) ? parsed.page : 1
  );
  const [rows, setRows] = useState(
    !_.isUndefined(parsed.rows) ? parsed.rows : 20
  );
  const [showAll, setShowall] = useState(
    !_.isUndefined(parsed.showall) ? parsed.showall : ""
  );
  const [loading, setLoading] = useState(true);
  const [currentSort, setSort] = useState(
    !_.isUndefined(parsed.sort) ? parsed.sort : "r"
  );
  const [currentArea, setArea] = useState(
    !_.isUndefined(parsed.area) ? parsed.area : ""
  );
  const [currentParams, setParams] = useState("");
  const [currentTag, setTag] = useState(
    !_.isUndefined(parsed.tag) ? parsed.tag : null
  );
  const [currentfromDate, setfromDate] = useState(
    !_.isUndefined(parsed.from_date) ? parsed.from_date : null
  );
  const [currenttoDate, settoDate] = useState(
    !_.isUndefined(parsed.to_date) ? parsed.to_date : null
  );
  const [currentCategory, setCategory] = useState(
    !_.isUndefined(parsed.category) ? parsed.category : null
  );
  const [currentPublication, setPublication] = useState(
    !_.isUndefined(parsed.pub) ? parsed.pub : null
  );
  const [currentF, setF] = useState(1);
  const [currentRepeatPico, setRepeatPico] = useState(true);
  const [isStarred, setStarred] = useState([]);
  const [snippetshow, setSnippetShow] = useState(!isMobile);
  const [exportshow, setExportShow] = useState(false);
  const [emailexportshow, setEmailExportShow] = useState(false);
  const [libkeymdlshow, setLibkeyMdlShow] = useState(false);
  const [surveymdlshow, setSurveyMdlShow] = useState(false);
  const [emailexportmessage, setEmailExportMessage] = useState("");
  const [emailexportto, setEmailExportTo] = useState("");
  const [exportbuttonshow, setExportbuttonShow] = useState(true);
  const [articleChecked, setArticleChecked] = useState([]);

  // the set of results we want to use as source for Connected
  const [articleConnect, setArticleConnect] = useState([]);

  // the set of results that have been clicked on
  const [articleClicked, setArticleClickedOrig] = useState([]);

  // proxy for setArticleClickedOrig which also calls setArticleConnect if length>=2
  const setArticleClicked = (value) => {
    if (value && value.length >= 2) {
      setArticleConnect(value);
    } else {
      setArticleConnect([]);
    }
    return setArticleClickedOrig(value);
  };

  // helper method passed in to ResultList - when "ShowRelated" is clicked for a document, we set that as the source for "Connected"
  const showRelated = (document) => {
    setArticleConnect([document]);
  };

  // when only one document is the source for Connected, auto-submit
  useEffect(() => {
    if (articleConnect?.length === 1) {
      postToConnectedService();
    }
  }, [articleConnect]);

  const [exportdata, setExportData] = useState("");
  const [exporttype, setExportType] = useState(".CSV");
  const [disableGo, setGo] = useState(true);
  const [proNotification, setProNotification] = useState(false);
  const [isInstitutionPro, setInstitutionPro] = useState({});
  const [firstPage, setFirstPage] = useState(1);
  const [continousProCheck, setContinousProCheck] = useState(0);
  const [categoryshow, setCategoryShow] = useState(!isMobile);
  const [childInstitutions, setChildInstitutions] = useState([]);
  const [institutionLibId, setInstitutionLibId] = useState("");
  const [previousCriteria, setPreviousCriteria] = useState("");
  const [dois, setDOIs] = useState(null);

  const [connectedResults, setConnectedResults] = useState(null);

  const defaultFactors = {
    top: 5,
    clicks: 1,
    referenced: 1,
    related: 1,
    cites: 1,
    keywords: 1,
    included: 1,
  };
  const localFactorsKey = "connectedFactors";
  const localFactorsJson = localStorage.getItem(localFactorsKey);
  const initialFactors = localFactorsJson
    ? JSON.parse(localFactorsJson)
    : defaultFactors;
  const [factors, setFactorsOrig] = useState(initialFactors);
  const setFactors = (newFactors) => {
    setFactorsOrig(newFactors);
    localStorage.setItem(localFactorsKey, JSON.stringify(newFactors));
  };
  const [connectedSearchId, setConnectedSearchId] = useState(null);

  // calculate if the current user is Pro or not, depending on their own/their institution
  const isPro =
    (!_.isUndefined(props.isPro) ? props.isPro : false) ||
    (!_.isEmpty(isInstitutionPro) ? true : false);
  var get_search_type = !_.isUndefined(props.location.state)
    ? props.location.state.search_type
    : getQueryStringValue("search_type");
  function onClickConnected(document) {
    axios
      .post(
        "https://wfu1t3sekd.execute-api.us-east-1.amazonaws.com/onclick",
        {
          document: document,
          searchId: connectedSearchId,
        },
        {
          withCredentials: false,
        }
      )
      .then(() => {
        console.log("onClick recorded");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function postToConnectedService() {
    console.log("Posting to Connected service");
    const startTime = new Date();

    factors.top = isPro ? 100 : 3;

    setConnectedResults({ summary: "Calculating..." });

    axios
      .post(
        "https://wfu1t3sekd.execute-api.us-east-1.amazonaws.com/",
        {
          documents: articleConnect,
          keywords: currentParams.criteria || "",
          userId: userId,
          factors: factors,
        },
        {
          withCredentials: false,
        }
      )
      .then((result) => {
        const results = result?.data?.results || {};

        setConnectedSearchId(results.searchId);

        if (results.documents && results.documents.length) {
          results.documents.map((document, index) => {
            document.index = index;
          });
        }

        const endTime = new Date();
        const elapsed = endTime - startTime;

        results.metrics = `Elapsed: ${elapsed}ms`;

        setConnectedResults(results);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const [connectedIsShown, setConnectedIsShown] = useState(false);

  // if the set of clicked articles changes, and we're already displaying connected results - resubmit
  useEffect(() => {
    if (connectedIsShown) {
      postToConnectedService();
    }
  }, [articleClicked]);
  async function toggleSurvey() {
    let srchParams = getParameters();
    let hide_survey =
      !_.isUndefined(store.get("hide_survey")) &&
      !_.isNull(store.get("hide_survey")) &&
      store.get("hide_survey") !== ""
        ? JSON.parse(store.get("hide_survey"))
        : false;
    let simpleSearch = srchParams.criteria.split(" ");
    if (
      srchParams.search_type == "standard" &&
      simpleSearch.length < 2 &&
      hide_survey == false
    ) {
      setSurveyMdlShow(true);
    }
  }
  async function handleSurvey(e) {
    setSurveyMdlShow(false);
    store.set("hide_survey", true);
  }
  /**
   * send Search request to Backend API
   */
  async function fetchData() {
    let searchParams = getParameters();

    axios
      .get(
        !_.isUndefined(process.env.REACT_APP_API_URL)
          ? process.env.REACT_APP_API_URL + "/Search"
          : "/Search",
        {
          params: searchParams,
          withCredentials: true,
        },
        {
          withCredentials: true,
        }
      )

      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
          setLoading(false);
          setF(
            !_.isUndefined(res.data.summary.fvalue)
              ? res.data.summary.fvalue
              : 1
          );
          setRepeatPico(
            !_.isUndefined(res.data.summary.repeat_pico)
              ? res.data.summary.repeat_pico
              : 1
          );
          setStarred(
            !_.isUndefined(res.data.summary.starred_data)
              ? res.data.summary.starred_data
              : []
          );
          setInstitutionPro(
            !_.isUndefined(res.data.summary.institution_auth)
              ? res.data.summary.institution_auth
              : {}
          );

          // only clear the list of clicked articles if the search terms have changed
          if (previousCriteria !== searchParams.criteria) {
            setArticleClicked([]);
            setPreviousCriteria(searchParams.criteria);
          }
          continousAuth(res);
        } else {
          const error = new Error(res.error_message);
          //setErrors(res.error_message);
        }
      })
      .catch((error) => {
        //console.log(error);
        // setErrors(error.response.data.error_message);
        setLoading(false);
      });
  }
  /**
   * Continous Authentication/Pro Check
   * @param {*} res
   */
  function continousAuth(res) {
    if (
      !_.isUndefined(res.data.summary.institution_auth) ||
      !_.isUndefined(res.data.summary.isPro)
    ) {
      if (!res.data.summary.isPro)
        setAuthTokens(updateTokens(authTokens, "isPro", ""));

      if (_.isNull(res.data.summary.institution_auth))
        store.set("instution_auth_tokens", { institution_auth: null });
      setContinousProCheck(res.data.summary);
      if (!res.data.summary.isPro) {
        setAuthTokens(updateTokens(authTokens, "isPro", ""));
        store.remove("recent");
      }

      if (res.data.summary.user) {
        setUserId(res.data.summary.user.user_id);
      }
    }
  }
  /**
   * Export Search
   * @param {*} keywords
   */
  async function fetchExport(keywords) {
    axios
      .get(
        !_.isUndefined(process.env.REACT_APP_API_URL)
          ? process.env.REACT_APP_API_URL + "/exportSearch"
          : "/exportSearch",
        {
          params: getParameters(),
          withCredentials: true,
        }
      )

      .then((res) => {
        if (res.status === 200) {
          //CSV working
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          let str_now = new Date();
          str_now =
            "tripExport" +
            str_now.getFullYear() +
            str_now.getMonth() +
            1 +
            str_now.getDate() +
            str_now.getHours() +
            str_now.getMinutes() +
            str_now.getSeconds();
          const filename =
            exporttype === ".CSV" ? str_now + ".csv" : str_now + ".ris";
          link.href = url;
          link.setAttribute("download", filename);

          document.body.appendChild(link);
          link.click();
          setExportbuttonShow(true);
        } else {
          const error = new Error(res.error_message);
          setErrors(res.error_message);
        }
      })
      .catch((error) => {
        setProNotification(true);
        setExportbuttonShow(false);
        setErrors("Access Denied! Unauthorized to perform this action.");
        setLoading(false);
      });
  }
  /**
   * email export
   * @param {*} resultset
   */
  async function emailExport(resultset) {
    var params = getParameters();
    var checklist = [];

    for (let res of resultset) {
      checklist.push(res.id);
    }
    params["resultset"] = checklist;
    params["articlesChecked"] = articleChecked;

    axios
      .post(
        !_.isUndefined(process.env.REACT_APP_API_URL)
          ? process.env.REACT_APP_API_URL + "/emailSearch"
          : "/emailSearch",
        {
          params: params,
        },
        {
          withCredentials: true,
        }
      )

      .then((res) => {
        if (res.status === 200) {
          if (_.isUndefined(res.data.error_message)) {
            setEmailExportMessage(
              !_.isUndefined(res.data.email) ? res.data.email.message : ""
            );
            setExportbuttonShow(true);
            setUserData(res.data);
            setEmailExportShow(true);
          } else {
            // const error = new Error(res.error_message);
            setErrors(res.data.error_message);
            setExportbuttonShow(true);
          }
        }
      })
      .catch((error) => {
        setExportbuttonShow(true);
        setProNotification(true);
        setErrors(error);
        setLoading(false);
      });
  }
  /**
   * sendEmail Export
   * @param {*} resultset
   */
  async function sendEmailExport() {
    axios
      .post(
        !_.isUndefined(process.env.REACT_APP_API_URL)
          ? process.env.REACT_APP_API_URL + "/emailExportSend"
          : "/emailExportSend",
        {
          params: { to: emailexportto, message: emailexportmessage },
        },
        {
          withCredentials: true,
        }
      )

      .then((res) => {
        if (res.status === 200) {
          if (_.isUndefined(res.data.error_message)) {
            setUserData(res.data);
          } else {
            // const error = new Error(res.error_message);
            setErrors(res.data.error_message);
            setExportbuttonShow(true);
          }
        }
      })
      .catch((error) => {
        setExportbuttonShow(true);
        setProNotification(true);
        setErrors(error);
        setLoading(false);
      });
  }

  /**
   * Checks if the string has any brackets
   * @param {string} input - The input string to check
   * @returns {boolean} - True if the string has any brackets
   */
  const hasPartialBrackets = (input) => {
    const openBrackets = (input.match(/\(/g) || []).length;
    const closeBrackets = (input.match(/\)/g) || []).length;
    return openBrackets > 0 || closeBrackets > 0;
  };

  /**
   * Converts input string to proper boolean expression while preserving phrases and quotes
   * @param {string} input - The input string to convert
   * @returns {string} - Properly formatted boolean expression
   */
  const convertToBooleanExpression = (input) => {
    try {
      console.log(`Processing input: "${input}"`);

      if (!input.trim()) {
        return "";
      }

      // Check if the string has any brackets
      if (!hasPartialBrackets(input)) {
        console.log("No brackets found, returning original input");
        return input;
      }

      // Store quoted strings and replace with placeholders
      const quotes = [];
      let processedInput = input.replace(/"[^"]+"/g, (match) => {
        quotes.push(match);
        return `__QUOTE${quotes.length - 1}__`;
      });

      // Standardize operators to uppercase
      processedInput = processedInput
        .replace(/\bAND\b/gi, "AND")
        .replace(/\bOR\b/gi, "OR")
        .replace(/\bNOT\b/gi, "NOT");

      // Split by operators while preserving them
      const operators = ["AND", "OR", "NOT"];
      const parts = [];
      let currentPart = "";

      processedInput.split(/\s+/).forEach((word) => {
        if (operators.includes(word)) {
          if (currentPart.trim()) {
            parts.push(currentPart.trim());
          }
          parts.push(word);
          currentPart = "";
        } else {
          currentPart += (currentPart ? " " : "") + word;
        }
      });
      if (currentPart.trim()) {
        parts.push(currentPart.trim());
      }

      // Process each part
      const result = parts
        .map((part) => {
          if (operators.includes(part)) {
            return part;
          }

          // Replace quote placeholders
          let processed = part;
          quotes.forEach((quote, index) => {
            processed = processed.replace(`__QUOTE${index}__`, quote);
          });

          // Always wrap in brackets, including quoted strings
          // Don't add brackets if the part already has them
          if (!processed.startsWith("(") || !processed.endsWith(")")) {
            return `(${processed})`;
          }
          return processed;
        })
        .join(" ");

      console.log(`Conversion result: "${result}"`);

      return result;
    } catch (error) {
      console.error(`Error: ${error.message}`);
      return "Error processing boolean expression";
    }
  };

  useEffect(() => {
    document.body.classList.remove("homepage");
    var criteria = !_.isUndefined(props.location.state)
      ? props.location.state.criteria
      : "";
    // document.title = criteria + " -  Trip Database";
    // let cri = convertToBooleanExpression(criteria);
    // console.log("heer", cri);
    fetchData(criteria);
    // toggleSurvey(criteria);
  }, [
    currentPage,
    currentSort,
    currentArea,
    currentTag,
    // currentfromDate,
    currentCategory,
    showAll,
    currentPublication,
    institutionLibId,
    dateRefresh,
    rows,
  ]);
  // const sanatizeBooleans = (param) => {
  //   return param
  //     .toLowerCase()
  //     .replace(/ not\(/gi, " NOT (")
  //     .replace(/ or\(/gi, " OR (")
  //     .replace(/ and\(/gi, " AND (")
  //     .replace(/\)not /gi, ") NOT ")
  //     .replace(/\)or /gi, ") OR ")
  //     .replace(/\)and /gi, ") AND ")
  //     .replace(/ or /gi, " OR ")
  //     .replace(/ not /gi, " NOT ")
  //     .replace(/ and /gi, " AND ")
  //     .replace(/“/gi, '"')
  //     .replace(/”/gi, '"')
  //     .trim();

  // };
  /**
   * prepare search parameters
   */
  function getParameters() {
    var search_type = !_.isUndefined(props.location.state)
      ? props.location.state.search_type
      : getQueryStringValue("search_type");
    var params = {
      criteria: !_.isUndefined(props.location.state)
        ? props.location.state.criteria
        : sanatizeBooleans(getQueryStringValue("criteria")),
      search_type: !_.isUndefined(search_type) ? search_type : "standard",
      page: currentPage,
      sort: currentSort,
      clinical: currentArea,
      category_id: currentCategory,
      tags: currentTag,
      from_date: currentfromDate,
      to_date: currenttoDate,
      publication_id: currentPublication,
      export_data: exportdata,
      export_type: exporttype,
      articles: articleChecked,
      showall: showAll,
      from_date_full: getQueryStringValue("from_date_full"),
      rows: rows,
    };
    document.title = params.criteria + " -  Trip Database";

    switch (search_type) {
      case "pico":
        params["intervention"] = !_.isUndefined(props.location.state)
          ? props.location.state.intervention
          : getQueryStringValue("intervention");
        params["comparison"] = !_.isUndefined(props.location.state)
          ? props.location.state.comparison
          : getQueryStringValue("comparison");
        params["outcome"] = !_.isUndefined(props.location.state)
          ? props.location.state.outcome
          : getQueryStringValue("outcome");
        params["f"] = currentF;
        params["repeat_pico"] = currentRepeatPico;

        break;
      case "advanced":
        params["allwordsfield"] = !_.isUndefined(props.location.state)
          ? props.location.state.allwordsfield
          : getQueryStringValue("allwordsfield");
        params["anywordsfield"] = !_.isUndefined(props.location.state)
          ? props.location.state.anywordsfield
          : getQueryStringValue("anywordsfield");
        params["exactphrasefield"] = !_.isUndefined(props.location.state)
          ? props.location.state.exactphrasefield
          : getQueryStringValue("exactphrasefield");
        params["excludingwordsfield"] = !_.isUndefined(props.location.state)
          ? props.location.state.excludingwordsfield
          : getQueryStringValue("excludingwordsfield");
        params["anywords"] = !_.isUndefined(props.location.state)
          ? props.location.state.anywords
          : getQueryStringValue("anywords");
        params["exactphrase"] = !_.isUndefined(props.location.state)
          ? props.location.state.exactphrase
          : getQueryStringValue("exactphrase");
        params["excludingwords"] = !_.isUndefined(props.location.state)
          ? props.location.state.excludingwords
          : getQueryStringValue("excludingwords");
        params["advfrom"] = !_.isUndefined(props.location.state)
          ? props.location.state.advfrom
          : getQueryStringValue("advfrom");
        params["advto"] = !_.isUndefined(props.location.state)
          ? props.location.state.advto
          : getQueryStringValue("advto");
        params["proximity"] = !_.isUndefined(props.location.state)
          ? props.location.state.proximity
          : getQueryStringValue("proximity");
        break;
    }
    setParams(params);
    return params;
  }
  /**
   * Todo : Improve this further
   * Safety locks to look for empty data sets
   */
  var summary_set = "",
    documents_set = "",
    categories_set = "",
    categories_set_child = "",
    categories_pro = "",
    libkeydata = "",
    clinical_area_set = [],
    refinements_set = [],
    publications_set = [];
  if (data && data.summary) {
    const { summary, documents } = data;

    summary_set = summary;
    documents_set = documents;
    categories_set = summary.all_categories ? summary.all_categories : [];
    categories_set_child = summary.child_categories
      ? summary.child_categories
      : [];
    categories_pro = summary.pro_categories ? summary.pro_categories : [];
    clinical_area_set = summary.clinical_area ? summary.clinical_area : [];
    refinements_set = summary.refinements ? summary.refinements : [];
    libkeydata = summary.libkeydata ? summary.libkeydata : null;

    publications_set = summary.publications_data
      ? summary.publications_data
      : [];
  }

  /**
   * Change Page function
   * @param {int} pageNumber
   */
  const paginate = (e, pageNumber) => {
    setQueryStringValue("page", pageNumber);
    setCurrentPage(pageNumber);
    window.scrollTo({
      top: 180,
      left: 0,
      behavior: "smooth",
    });
    e.preventDefault();
  };
  /**
   * Sort Selection
   * @param {*} e
   * @param {*} pageNumber
   */
  const sortSelection = (sort_selection) => {
    setQueryStringValue("sort", sort_selection);
    setSort(sort_selection);
  };
  /**
   * category Selection
   * @param {*} e
   * @param {*} category
   */
  const categorySelect = (e, category) => {
    if (category == currentCategory) {
      category = "";
    }
    setQueryStringValue("category", category);
    setQueryStringValue("page", 1);
    setCategory(category);
    setCurrentPage(1);
    e.preventDefault();
  };
  const showallSelect = (e, param) => {
    setQueryStringValue("showall", param);
    setQueryStringValue("page", 1);
    setShowall(param);
    setCurrentPage(1);
    window.scrollTo({
      top: 180,
      left: 0,
      behavior: "smooth",
    });
    e.preventDefault();
  };
  const setResultRows = (param) => {
    setQueryStringValue("rows", param);
    setRows(param);
    setCurrentPage(1);
    window.scrollTo({
      top: 180,
      left: 0,
      behavior: "smooth",
    });
  };
  const suggestionSelect = (e, keyword) => {
    setQueryStringValue("criteria", keyword);
    //setCri(category);
    e.preventDefault();
  };
  /**
   * clinical area Selection
   * @param {*} e
   * @param {*} area
   */
  const clinicalareaSelect = (e, area) => {
    let value = e.target.checked ? area : "";
    setQueryStringValue("area", value);
    setArea(value);
    setCurrentPage(1);
    setQueryStringValue("page", 1);
    e.preventDefault();
  };

  /**
   * refinement Selection
   * @param {*} e
   * @param {*} type
   * @param {*} value
   */
  const refinementSelect = (e, type, val) => {
    let value = e.target.checked ? val : "";

    switch (type) {
      case "activity":
        break;
      case "tag":
        setQueryStringValue("tag", value);
        setTag(value);
        break;
      case "from_date":
        setQueryStringValue("from_date", value);
        setfromDate(value);
        break;
    }

    e.preventDefault();
  };

  /**
   * publication Selection
   * @param {*} e
   * @param {*} publication
   */
  const publicationSelect = (e, publication) => {
    if (publication == currentPublication) {
      publication = "";
    }
    setPublication(publication);
    setCurrentPage(1);
    setQueryStringValue("pub", publication);
    setQueryStringValue("page", 1);

    e.preventDefault();
  };

  const dateRangeSelect = (e) => {
    setQueryStringValue("from_date", currentfromDate);
    setQueryStringValue("to_date", currenttoDate);
    setCurrentPage(1);
    setQueryStringValue("page", 1);
    setDateRefresh(!dateRefresh);
    e.preventDefault();
  };

  const handleExport = (event) => {
    var pro =
      (!_.isUndefined(props.isPro) ? props.isPro : false) ||
      (!_.isEmpty(isInstitutionPro) ? true : false);

    if (pro && continousProCheck) {
      setProNotification(false);
      setExportbuttonShow(false);
      if (exporttype == "Email") emailExport(documents_set);
      else fetchExport();
    } else {
      setProNotification(true);
    }
  };
  const handleClose = (e) => {
    setEmailExportShow(false);
    setExportbuttonShow(true);
    setLibkeyMdlShow(false);
    // e.preventDefault();
  };
  const handleEmailExport = (e) => {
    // emailExport(documents_set);
    sendEmailExport();
    setEmailExportShow(false);
    e.preventDefault();
  };
  var current_selections = {
    currentArea: currentArea,
    currentTag: currentTag,
    currentfromDate: currentfromDate,
    currentCategory: currentCategory,
  };

  return (
    <div className="container-fluid">
      <EmailExportMdl
        user={userdata}
        handleClose={handleClose}
        handleEmailExport={handleEmailExport}
        show={emailexportshow}
        emailexportto={emailexportto}
        emailexportmessage={emailexportmessage}
        setEmailExportMessage={setEmailExportMessage}
        setEmailExportTo={setEmailExportTo}
      />
      <NHSLibkeyMdl
        childInstitutions={childInstitutions}
        handleClose={handleClose}
        show={libkeymdlshow}
        setInstitutionLibId={setInstitutionLibId}
      />
      {/* <SurveyMdl
        getParameters={getParameters}
        handleClose={handleSurveyMdl}
        show={surveymdlshow}
        setSurveyMdlShow={setSurveyMdlShow}
      /> */}
      <Row className="row search-term-container">
        <ResultHeader
          props={props}
          totalArticles={
            summary_set && summary_set.total ? summary_set.total : ""
          }
          loading={loading}
          keyword={
            !_.isUndefined(props.location.state)
              ? props.location.state.criteria
              : ""
          }
          sort={currentSort}
          categories_pro={categories_pro}
          currentParams={currentParams}
          setSurveyMdlShow={setSurveyMdlShow}
          surveymdlshow={surveymdlshow}
          handleSurvey={handleSurvey}
        />
      </Row>
      <hr></hr>

      <div className="row result-filters-row">
        {/* Sidebar */}
        <div className="col-sm-auto result-filters-container">
          <ResultCategories
            isPro={isPro}
            isInstitutionPro={isInstitutionPro}
            categories_set={categories_set}
            categories_set_child={categories_set_child}
            categories_pro={categories_pro}
            clinical_area_set={clinical_area_set}
            refinements_set={refinements_set}
            current_selections={current_selections}
            loading={loading}
            catToggle={catToggle}
            from_date={currentfromDate}
            to_date={currenttoDate}
            categorySelect={categorySelect}
            clinicalareaSelect={clinicalareaSelect}
            refinementSelect={refinementSelect}
            setCatToggle={setCatToggle}
            setfromDate={setfromDate}
            settoDate={settoDate}
            dateRangeSelect={dateRangeSelect}
          />
        </div>
        {/* End Sidebar */}
        {/* Results details */}
        <div className="col results-list-container">
          <div className="results-list">
            {/* REsult header  action */}
            <div className="results-actions-container results-actions-container__desktop">
              <NHSLibkey
                data={_.isObject(libkeydata) ? libkeydata : { id: 0 }}
                setLibkeyMdlShow={setLibkeyMdlShow}
                setChildInstitutions={setChildInstitutions}
                institutionLibId={institutionLibId}
              />
              <ResultActions
                isPro={isPro}
                sort={currentSort}
                sortSelection={sortSelection}
                setSnippetShow={setSnippetShow}
                snippetshow={snippetshow}
                setExportShow={setExportShow}
                setProNotification={setProNotification}
                exportshow={exportshow}
                isdesktop={true}
                categoryshow={categoryshow}
                setCategoryShow={setCategoryShow}
                rows={rows}
                setResultRows={setResultRows}
                criteria={currentParams.criteria}
              />
              <hr></hr>
            </div>
            <div className="results-actions-container results-actions-container__mobile">
              <ResultActions
                isPro={isPro}
                sort={currentSort}
                sortSelection={sortSelection}
                setSnippetShow={setSnippetShow}
                snippetshow={snippetshow}
                setExportShow={setExportShow}
                setProNotification={setProNotification}
                exportshow={exportshow}
                isdesktop={false}
                categoryshow={categoryshow}
                setCategoryShow={setCategoryShow}
                rows={rows}
                setResultRows={setResultRows}
                criteria={currentParams.criteria}
              />
              <hr></hr>
            </div>
            <div>
              {exportshow && (
                <Col md="12">
                  <Alert
                    variant="light"
                    onClose={() => setExportShow(!exportshow)}
                    dismissible
                  >
                    <div className="d-flex align-items-center mb-3">
                      <Alert.Heading className="mb-0 me-2">
                        Export Articles
                      </Alert.Heading>
                      {!isPro && (
                        <div className="d-flex pro-label-container">
                          <span className="pro-label me-2">PRO</span>
                          <span className="pro-description me-2">
                            Only Pro users can use this feature.{" "}
                            <Alert.Link href="/Pro">Get PRO Now</Alert.Link>
                          </span>
                        </div>
                      )}
                    </div>

                    <Row>
                      <Col md="12">
                        <div
                          key={`inline-radio`}
                          className="d-flex flex-column gap-2 mb-2"
                        >
                          <Form.Check
                            inline
                            name="export_data"
                            label={
                              <span
                                style={{
                                  fontWeight:
                                    exportdata === "all_selected"
                                      ? "bold"
                                      : "normal",
                                }}
                              >
                                {articleChecked.length > 0
                                  ? `Selected results only (${
                                      articleChecked.length
                                    } article${
                                      articleChecked.length > 1 ? "s" : ""
                                    } selected)`
                                  : "Selected results only (no articles selected - use check boxes below next to articles)"}
                              </span>
                            }
                            type="radio"
                            id={`inline-radio-1`}
                            value="all_selected"
                            onChange={(e) => {
                              setExportData(e.target.value);
                              setGo(false);
                            }}
                            checked={exportdata === "all_selected"}
                          />
                          <Form.Check
                            inline
                            name="export_data"
                            label={
                              <span
                                style={{
                                  fontWeight:
                                    exportdata === "all_page"
                                      ? "bold"
                                      : "normal",
                                }}
                              >
                                {`All results on this page (${rows})`}
                              </span>
                            }
                            type="radio"
                            id={`inline-radio-2`}
                            value="all_page"
                            onChange={(e) => {
                              setExportData(e.target.value);
                              setGo(false);
                            }}
                            checked={exportdata === "all_page"}
                          />
                          <Form.Check
                            inline
                            name="export_data"
                            type="radio"
                            id={`inline-radio-3`}
                            value="all_results"
                            onChange={(e) => {
                              setExportData(e.target.value);
                              setGo(false);
                            }}
                            checked={exportdata === "all_results"}
                            label={
                              <span
                                style={{
                                  fontWeight:
                                    exportdata === "all_results"
                                      ? "bold"
                                      : "normal",
                                }}
                              >
                                All results ({summary_set?.total || 0})
                                <small className="text-muted ms-2">
                                  *large number of results takes longer to
                                  compile
                                </small>
                              </span>
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <div className="export-alert-actions">
                        <p>Download as</p>
                        <Form.Control
                          as="select"
                          onChange={(e) => setExportType(e.target.value)}
                          value={exporttype}
                        >
                          <option value=".CSV">.CSV</option>
                          <option value=".RIS">.RIS</option>
                          <option value="Email">Email</option>
                        </Form.Control>

                        {exportbuttonshow ? (
                          <Button
                            variant="primary"
                            type="submit"
                            onClick={(e) => handleExport(e)}
                            disabled={!isPro || disableGo}
                          >
                            {isPro ? (
                              <>
                                Download <i className="fa fa-download"></i>
                              </>
                            ) : (
                              "Download Articles - Pro Subscription Only"
                            )}
                          </Button>
                        ) : (
                          <Spinner animation="border" variant="success" />
                        )}
                      </div>
                    </Row>
                  </Alert>
                </Col>
              )}
            </div>
            {/* End Result header */}
            {/* Result article */}
            {hasError ? (
              <Alert variant="danger" dismissible>
                <Alert.Heading>{hasError}!</Alert.Heading>
                <p>
                  We cannot process your request, review your search parameters
                  and try again!
                </p>
              </Alert>
            ) : (
              ""
            )}
            <ResultList
              isPro={isPro}
              resultset={documents_set ? documents_set : []}
              publicationset={publications_set}
              start={summary_set.start}
              loading={loading}
              publicationSelect={publicationSelect}
              searchParams={currentParams}
              setStarred={setStarred}
              isStarred={isStarred}
              snippetshow={snippetshow}
              articleChecked={articleChecked}
              setArticleChecked={setArticleChecked}
              articleClicked={articleClicked}
              setArticleClicked={setArticleClicked}
              libkeydata={libkeydata}
              categorySelect={categorySelect}
              dois={dois}
              currentCategory={currentCategory}
              showRelated={showRelated}
            />
            <Pagination
              postsPerPage={rows}
              totalArticles={
                summary_set && summary_set.total ? summary_set.total : 0
              }
              paginate={paginate}
              firstPage={firstPage}
              setFirstPage={setFirstPage}
              currentPage={currentPage}
              showall={showAll}
              showallSelect={showallSelect}
              isPro={isPro}
              search_type={get_search_type}
            />
          </div>
          {/* Connected Articles */}
          {articleConnect?.length != 0 && (
            <ConnectedResults
              clicked={articleConnect}
              results={connectedResults}
              factors={factors}
              setFactors={setFactors}
              onSubmit={postToConnectedService}
              onClick={onClickConnected}
              isPro={isPro}
              onShowHide={setConnectedIsShown}
              search_type={
                !_.isUndefined(props.location.state)
                  ? props.location.state.search_type
                  : getQueryStringValue("search_type")
              }
            />
          )}
          {/* END Connected Articles */}
        </div>
        {/* End Results */}
      </div>
    </div>
  );
};

export default Searchresult;
